<template>
  <div class="c-library__body c-library__body_announcementdetail" @mouseleave = "resetActive">
    <div class="c-library__announcementdetail" >
      <div class="c-library__announcementdetail-container">
          <div class="c-library__announcementdetail-container-back">
            <a  @click='routerRedirect("/library/announcements")' class="c-sidebar__link" :class='{"c-sidebar__link_active": routeName =="Announcements"}' @touchstart="backIconHovered = true" @touchend="backIconHovered = false" @mouseover="backIconHovered = true" @mouseleave="backIconHovered = false">
            <img v-if="backIconHovered == false" :src="imgBack" class="c-library__announcementdetail-container-back-icback" /> 
 <img v-else-if="backIconHovered == true" :src="imgBackActive" class="c-library__announcementdetail-container-back-icback" />
      <span class="c-sidebar__link-text">Back</span></a>

          </div>
          <div class="c-library__announcementdetail-container-containertitle">
           <div class="c-library__announcementdetail-container-containertitle-title">{{ listShare[indexData].title }}</div>

            <div class="c-library__announcementdetail-container-containertitle-date">{{ listShare[indexData].date }}</div>
          </div>


          <div class="c-library__announcementdetail-container-containerimg">
            <img class="c-library__announcementdetail-container-containerimg-img" :src="listShare[indexData].img" />
          </div>
          <div class="c-library__announcementdetail-container-desc">
            <span v-html="listShare[indexData].desc"></span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'AnnouncementDetailPage',
    data() {
      return {
        screenWidth: window.innerWidth,
        listShare:[
          {
            id: 0,
            img:require('@/assets/images/announcement/announcement_1.svg'),
            title:'Sparrow Receives In-Principle Approval for Digital Payment Token Services',
            date:'24 June 2022',
            date_format: '2022-06-24',
            desc:`
            <p class='c-library__announcementdetail-desc-text'>SINGAPORE, [24 June 2022] - Sparrow Tech Private Limited is pleased to receive in-principle approval from the Monetary Authority of Singapore (&ldquo;MAS&rdquo;) on its application for a major payment institution (&ldquo;MPI&rdquo;) licence to provide Digital Payment Token (DPT) services under the Payment Services Act 2019 in Singapore. Sparrow, which specializes in digital asset products and solutions, is currently working to obtain the formal MPI licence.&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&ldquo;With the rising adoption of cryptocurrencies among financial institutions and wealth managers, regulatory compliance is essential to ensure digital asset activities are conducted in a safe, secure and trustworthy manner,&rdquo; said Kenneth Yeo, Chief Executive Officer of Sparrow.&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&ldquo;Our clients are concerned not just with risks and volatility, but more importantly, with authenticity and transparency. With the in-principle approval from the MAS, Sparrow will continue to serve clients with the highest compliance and security standards. We would also like to take this opportunity to thank the regulatory bodies for supporting the development of our home-grown company,&rdquo; added Yeo. &nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&ldquo;A robust and solid enterprise risk and compliance management program is critical, particularly in the cryptocurrency industry. Sparrow is dedicated to elevating its business processes and frameworks while increasing the trust and confidence among our clients,&rdquo; commented Chung Yee Mak, Chief Risk and Compliance Officer. &nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text-bold'><strong>Other recent developments in Sparrow</strong>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>Recently, Sparrow has started<a class="c-library__announcementdetail-desc-link" target="_blank" href="https://finance.yahoo.com/news/sparrow-offers-paynow-services-institutional-011200413.html">&nbsp;accepting PayNow transfers among institutional clients</a> so clients can easily buy or sell cryptocurrencies using fiat on Sparrow. The PayNow capability added convenience, security, and confidence to clients who are from the world of traditional finance.&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>In addition, the home-grown digital assets company<a class="c-library__announcementdetail-desc-link" target="_blank" href="https://finance.yahoo.com/news/sparrow-raises-us-4-4-024100786.html">&nbsp;raised US$4.45 million from Gain Loyal Ltd pursuant to its Series A Funding round</a> to accelerate its business in a strategic partnership. In total, Sparrow has closed approximately US$10 million in Series A funding from established partners. To support its fast-growing and innovative business, Sparrow has doubled its headcount and plans to continue actively hiring across all functions. &nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text-bold'><strong>About Sparrow</strong>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>Sparrow offers innovative digital asset products and solutions. Headquartered in Singapore, Sparrow works with financial institutions and family offices in designing<a class="c-library__announcementdetail-desc-link" target="_blank" href="https://sparrowexchange.com/institutional/wealth">&nbsp;bespoke digital asset solutions</a> to achieve customer-centric growth objectives without compromising regulatory, financial reporting, and compliance requirements.&nbsp;</p>`

             },

              {
                id: 1,
            img:require('@/assets/images/announcement/announcement_2_new.png'),
            title:'Sparrow awarded Major Payment Institution (MPI) licence to provide Digital Payment Token (DPT) services',
            date:'1 August 2022',
            date_format: '2022-08-01',
            desc:`
            <p class='c-library__announcementdetail-desc-text'>SINGAPORE - Sparrow is proud to announce that it has obtained a Major Payment Institution (MPI) licence under the Payment Services Act 2019 (PS Act) from the Monetary Authority of Singapore (MAS). <a class="c-library__announcementdetail-desc-link" target="_blank" href="https://sparrowexchange.com/library/announcements-detail">Sparrow was previously issued an In-Principle Approval (IPA) from MAS in June 2022 for their MPI licence application.</a></p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&ldquo;We can now provide digital payment token services with credibility through elevated compliance standards. Trust is something that we don’t take for granted. We are committed to providing exceptional service standards for institutions and high-net-worth individuals seeking to utilize our services. We are pleased to be one of the first few digital payment token service providers to be awarded with the MPI licence.” Kenneth Yeo, CEO of Sparrow expressed.&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>MAS has adopted a rigorous regulatory approach and thus far, only a select few digital payment token firms have been licensed. Mak Chung Yee, Chief Risk & Compliance Officer at Sparrow, adds: “At Sparrow, it has always been about addressing the increasing demands and diverse needs from regulators and clients. Now that we have obtained the MPI licence, we aim to ensure fair treatment and assurance among clients, sustain market integrity, maintain compliance soundness, and ensure we continue to advocate cryptocurrency standards in Singapore.” &nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text-bold'><a class="c-library__announcementdetail-desc-link c-library__announcementdetail-desc-link_extrabold" target="_blank" href="https://sparrowexchange.com/">About Sparrow</a>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>&nbsp;</p>
            <p class='c-library__announcementdetail-desc-text'>With its headquarters in Singapore, Sparrow employs a multifaceted strategy and comprehensive framework that allows clients to achieve the growth of their digital assets without compromising customer satisfaction, compliance with legal obligations, or cybersecurity standards. Additionally, Sparrow has acquired about US$10 million in Series A investment from reputable partners and enables PayNow transactions among institutional and high-net-worth clients so that they may easily purchase or sell cryptocurrencies using fiat on our own user-friendly trading platform.&nbsp;</p>`
             },

        ],
        active:'',
        backIconHovered : false,
        imgBack:require('@/assets/images/ic_arrow_left.svg'),
        imgBackActive:require('@/assets/images/ic_arrow_left_blue.svg'),
        routeName: this.$route.name,
      }
    },
     mounted:function(){
      // if(this.indexData == null){
      //   //this.routerRedirect("/library/announcements");
      //   this.$router.push('/library/announcements').catch(() => {});
      //   //this.$router.push({ name: 'Announcements' , params:{}});
      // }
    },
    // created:function(){
    //   if(this.indexData == null){
    //     //this.routerRedirect("/library/announcements");
    //     this.$router.push({ name: 'Announcements' , params:{}});
    //   }
    // },
    computed:{
      device(){
        var dev = this.listShare;
        for (var i = 0; i < dev.length; i++) {
          if (this.screenWidth <= 620) {
            dev[i].isLong = false;
            dev[2].isLong = true;
          }else if (this.screenWidth <= 1279) {
            dev[i].isLong = false;
            dev[8].isLong = true;
          }else {
            dev[i].isLong = false;
            dev[0].isLong = true;
            dev[1].isLong = true;
            dev[2].isLong = true;
            dev[3].isLong = true;
            dev[8].isLong = true;
          }
        }

        return dev;
      },
      indexData(){
        return this.$store.getters.getAnnouncementId;
      }
    },
    methods: {
      setActive(element) {
        this.active = element;
      },
      resetActive(){
        this.active = '';
      },
    }
  }
</script>
